var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('td', [_c('v-text-field', {
    staticClass: "pa-5",
    attrs: {
      "dense": "",
      "type": "text",
      "placeholder": "Name",
      "label": "Name",
      "disabled": _vm.FIELDS_DISABLED
    },
    model: {
      value: _vm.company.name,
      callback: function callback($$v) {
        _vm.$set(_vm.company, "name", $$v);
      },
      expression: "company.name"
    }
  })], 1), _c('td', [_c('v-select', {
    staticClass: "pa-5",
    attrs: {
      "dense": "",
      "items": _vm.companiesSectionData.typeOptions,
      "label": "Type",
      "disabled": _vm.FIELDS_DISABLED
    },
    model: {
      value: _vm.company.type,
      callback: function callback($$v) {
        _vm.$set(_vm.company, "type", $$v);
      },
      expression: "company.type"
    }
  })], 1), _c('td', [_c('v-combobox', {
    staticClass: "pa-5",
    attrs: {
      "items": _vm.ownershipOptions,
      "label": "Ownership",
      "dense": "",
      "disabled": _vm.FIELDS_DISABLED
    },
    model: {
      value: _vm.company.ownership,
      callback: function callback($$v) {
        _vm.$set(_vm.company, "ownership", $$v);
      },
      expression: "company.ownership"
    }
  })], 1), _c('td', [_c('v-menu', {
    ref: "established",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto",
      "disabled": _vm.FIELDS_DISABLED
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: '####-##-##',
            expression: "'####-##-##'"
          }],
          attrs: {
            "rules": _vm.dateRules,
            "hint": "YYYY-MM-DD",
            "label": "Established",
            "prepend-icon": "mdi-calendar",
            "disabled": _vm.FIELDS_DISABLED
          },
          on: {
            "blur": _vm.dateUpdate
          },
          model: {
            value: _vm.dateData,
            callback: function callback($$v) {
              _vm.dateData = $$v;
            },
            expression: "dateData"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.datePickerDate,
      callback: function callback($$v) {
        _vm.datePickerDate = $$v;
      },
      expression: "datePickerDate"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "active-picker": _vm.activePicker
    },
    on: {
      "update:activePicker": function updateActivePicker($event) {
        _vm.activePicker = $event;
      },
      "update:active-picker": function updateActivePicker($event) {
        _vm.activePicker = $event;
      },
      "change": function change($event) {
        var _vm2;

        return (_vm2 = _vm).saveDate.apply(_vm2, ['established'].concat(Array.prototype.slice.call(arguments)));
      }
    },
    model: {
      value: _vm.company.established,
      callback: function callback($$v) {
        _vm.$set(_vm.company, "established", $$v);
      },
      expression: "company.established"
    }
  })], 1)], 1), _c('td', [_c('v-icon', {
    attrs: {
      "disabled": _vm.FIELDS_DISABLED
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.removeCompany();
      }
    }
  }, [_vm._v(" mdi-delete ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }