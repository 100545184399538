var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSection', {
    attrs: {
      "id": _vm.section.key,
      "is-visible": _vm.isSectionVisible,
      "is-modified": _vm.isDataChanged,
      "section": _vm.section
    },
    on: {
      "onSectionSaveClick": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "section",
      fn: function fn() {
        return [_c('v-card-text', {
          staticClass: "mt-4"
        }, [_c('div', {
          staticClass: "mb-5"
        }, [_c('v-simple-table', {
          staticClass: "mt-5",
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn() {
              return [_c('tbody', _vm._l(_vm.companies, function (company, idx) {
                return _c('CompanyRow', {
                  key: idx,
                  attrs: {
                    "company": company,
                    "companyId": idx
                  },
                  on: {
                    "removeCompany": _vm.removeCompany
                  }
                });
              }), 1)];
            },
            proxy: true
          }])
        }), _c('v-btn', {
          attrs: {
            "color": "primary",
            "disabled": _vm.FIELDS_DISABLED
          },
          on: {
            "click": _vm.addCompany
          }
        }, [_vm._v(" Add new ")])], 1)])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }