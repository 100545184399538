<template>
  <BaseSection
    :id="section.key"
    :is-visible="isSectionVisible"
    :is-modified="isDataChanged"
    :section="section"
    @onSectionSaveClick="save"
  >
    <template #section>
      <v-card-text class="mt-4">
        <div class="mb-5">
          <v-simple-table class="mt-5">
            <template #default>
              <tbody>
                <CompanyRow v-for="(company, idx) in companies"
                  :key="idx" :company="company" :companyId="idx"
                  @removeCompany="removeCompany"
                />
              </tbody>
            </template>
          </v-simple-table>
          <v-btn color="primary" @click="addCompany" :disabled="FIELDS_DISABLED">
            Add new
          </v-btn>
        </div>
      </v-card-text>
    </template>
  </BaseSection>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

import SectionMixin from '@/mixins/SectionMixin';

import BaseSection from '@/components/ApplicationSections/BaseSection.vue';
import CompanyRow from '@/components/ApplicationSections/Components/CompanyComponent.vue';

export default {
  name: 'CompaniesSection',

  components: {
    BaseSection,
    CompanyRow,
  },

  mixins: [SectionMixin],

  props: {
    existedData: {
      type: Array,
      default() {
        return [];
      },
    },

    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isSectionVisible: false,

      initialCompanies: [],
      companies: [],
    };
  },

  async created() {
    await this.checkIsSectionVisible();

    this.initialCompanies = clone(this.existedData);
    this.companies = clone(this.existedData);

    this.$emit('componentReady');
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED']),
  },

  methods: {
    addCompany() {
      const companyData = {
        name: '',
        type: '',
        ownership: '',
        established: '',
      };

      this.companies.push(companyData);
    },

    removeCompany(idxCompany) {
      this.companies.splice(idxCompany, 1);
    },
  },
};
</script>
