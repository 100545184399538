<template>
  <tr>
    <td>
      <v-text-field
        dense
        type="text"
        v-model="company.name"
        placeholder="Name"
        label="Name"
        class="pa-5"
        :disabled="FIELDS_DISABLED"
      />
    </td>
    <td>
      <v-select
        dense
        v-model="company.type"
        :items="companiesSectionData.typeOptions"
        label="Type"
        class="pa-5"
        :disabled="FIELDS_DISABLED"
      />
    </td>
    <td>
      <v-combobox
        v-model="company.ownership"
        :items="ownershipOptions"
        label="Ownership"
        class="pa-5"
        dense
        :disabled="FIELDS_DISABLED"
      />
    </td>
    <td>
      <v-menu
        ref="established"
        v-model="datePickerDate"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
        :disabled="FIELDS_DISABLED"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-mask="'####-##-##'"
            v-model="dateData"
            :rules="dateRules"
            hint="YYYY-MM-DD"
            label="Established"
            prepend-icon="mdi-calendar"
            v-bind="attrs"
            v-on="on"
            :disabled="FIELDS_DISABLED"
            @blur="dateUpdate"
            ></v-text-field>
        </template>
        <v-date-picker
          v-model="company.established"
          :active-picker.sync="activePicker"
          @change="saveDate('established', ...arguments)"
        ></v-date-picker>
      </v-menu>
    </td>
    <td>
      <v-icon @click.prevent="removeCompany()" :disabled="FIELDS_DISABLED">
        mdi-delete
      </v-icon>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex';

import FeedOptionsMixin from '@/mixins/FeedOptionsMixin';

import { companiesSectionData } from '../dataOptions';

import { dateRequired, dateValidation } from '@/utils/validation';

export default {
  name: 'CompanyComponent',

  props: {
    company: {
      type: Object,
      default: () => {},
    },

    companyId: {
      type: Number,
      required: true,
    },
  },

  mixins: [FeedOptionsMixin],

  data() {
    return {
      companiesSectionData,

      activePicker: null,

      feedMainOptionsKey: {
        ownership: 'companiesSectionData.ownershipOptions',
      },

      datePickerDate: null,

      dateData: null,

      dateRules: [
        dateRequired('Established'),
        dateValidation('Established'),
      ],
    };
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED']),
  },

  methods: {
    async dateUpdate(event) {
      const value = await event.target.value;

      const isValueValid = Boolean(value.length === 10);

      if (isValueValid) {
        this.company.established = this.$options.filters.dateISOFormatter(value);
      }
    },

    saveDate(ref, date) {
      this.$refs[ref]?.save(date);
    },

    removeCompany() {
      this.$emit('removeCompany', this.companyId);
    },
  },

  created() {
    this.dateData = this.company.established;
  },

  watch: {
    'company.established': {
      handler(val) {
        this.dateData = val;
      },
    },

    termsDate(val) {
      if (val) {
        const activePickerEdit = () => {
          this.activePicker = 'YEAR';
        };

        setTimeout(activePickerEdit);
      }
    },
  },
};
</script>
